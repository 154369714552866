$(document).ready(function () {
  $('.form_with_ok_redirect').on("successed", function (e) {
    console.log("redirect removed")
  });

  $('.form_with_ok_content_change').on("successed", function (e) {
    $('#form_success_overlay').show()
  });

  $('.candidati').on("click", function (e) {
    $('#work_with_us_form_open_position_id').val( $(this).data('opid') );
  })

  $('.leggi').on("click", function (e) {
    $('#leggi_' + $(this).data('opid')).show();
  })

  $('.chiudi').on("click", function (e) {
    $('#leggi_' + $(this).data('opid')).hide();
  })


})