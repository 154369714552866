// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
import imagesLoaded from 'imagesloaded'
import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
jQueryBridget('masonry', Masonry, $);
jQueryBridget( 'imagesLoaded', imagesLoaded, $ );
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import './masonry_layout'
import './menu'
import './forms'



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)


// CSS

// import '../stylesheets/main.scss'
// import '../stylesheets/variables/variables.scss'
// import '../stylesheets/page_layouts/homepage.scss'
// import '../stylesheets/page_layouts/products.scss'
// import '../stylesheets/page_layouts/processes.scss'
// import '../stylesheets/page_layouts/group.scss'
// import '../stylesheets/layout/header.scss'
// import '../stylesheets/layout/footer.scss'