jQuery(document).ready(function($) {
    // mobile menu
    $('#hamburger_menu').click( function (){
        $('#mobile_menu_panel').slideDown();
        $('#hamburger_menu').hide();
        $('#close_menu').show();
        $('body').css('overflow-y','hidden');
    });
    $('#close_menu').click( function (){
        $('#mobile_menu_panel').slideUp();
        $('#hamburger_menu').show();
        $('#close_menu').hide();
        $('body').css('overflow-y','auto');
    });
    $('#mobile_menu .text_sub_menu').click( function (e) {
        if ( !$(e.target).hasClass('clicked') ){
            $('#mobile_menu').find('.clicked').removeClass('clicked');
            $('#mobile_menu').find('.sub_menu').slideUp();
            $(e.target).parent().find('.sub_menu').slideDown();
            $(e.target).addClass('clicked');
        }else{
            $(e.target).parent().find('.sub_menu').slideUp();
            $(e.target).removeClass('clicked');
        }
    });
});
